import { Select } from 'antd';
import React from 'react';
import { utils } from '../helpers';

const { Option } = Select;

export default class TimezoneSelect extends React.Component {
	static DefaultTZ = '';
	static DefaultTZAbbr = '';
	constructor(props) {
		super(props);

		this.opts = Object.keys(timezones)
			.sort()
			.map((tz) => (
				<Option key={tz} value={tz}>
					{timezones[tz]}
				</Option>
			));
	}

	render() {
		const { defaultValue, ...props } = this.props;
		return (
			<Select defaultValue={defaultValue || TimezoneSelect.DefaultTZ} {...props}>
				{this.opts}
			</Select>
		);
	}
}

try {
	TimezoneSelect.DefaultTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
} catch (e) {
	if (utils.isAdmin()) {
		console.error(e);
	}
}

try {
	TimezoneSelect.DefaultTZAbbr = /.*\s(.+)/.exec(new Date().toLocaleDateString(navigator.language, { timeZoneName: 'short' }))[1];
} catch (e) {
	try {
		TimezoneSelect.DefaultTZAbbr = new Date().toTimeString().match(new RegExp('[A-Z](?!.*[(])', 'g')).join('');
	} catch (err) {
		if (utils.isAdmin()) {
			console.error(err);
		}
	}
}

if (!TimezoneSelect.DefaultTZ) TimezoneSelect.DefaultTZ = 'America/Chicago';
if (!TimezoneSelect.DefaultTZAbbr) TimezoneSelect.DefaultTZAbbr = 'CST';

// copied from https://cdn.jsdelivr.net/npm/google-timezones-json/timezones.json
const timezones = {
	'Pacific/Honolulu': 'Hawaii Time',
	'America/Anchorage': 'Alaska Time',
	'America/Los_Angeles': 'Pacific Time',
	'America/Denver': 'Mountain Time',
	'America/Chicago': 'Central Time',
	'America/New_York': 'Eastern Time',
};

const timezoneAbbreviations = {
	'America/Los_Angeles': 'PST',
	'America/Chicago': 'CST',
	'America/New_York': 'EST',
	'America/Denver': 'MST',
	'America/Phoenix': 'MST',
	'America/Anchorage': 'AKST',
	'America/Honolulu': 'HST',
	'Pacific/Honolulu': 'HST',
	// Add more mappings as needed
};

export const getShortTZ = (timezone) => {
	return timezoneAbbreviations[timezone] || timezone;
};
