import { InputNumber, Row, Space } from 'antd';
import React, { CSSProperties, useState } from 'react';

import Break from '../../../../Break';
import HoverQuestion from '../../../../HoverQuestion';
import HexPicker from '../../../../global/HexPicker';
import AppSettingsStoreSelector from '../../components/StoreSelector';
import { AppSettingsTabProps } from '../../NativeAppSettings';
import { hoverStyle } from '../StylingTab';
import { DeleteOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import ResetInput from '../../components/ResetInput';

export const dirs: any = {
    'login': {
        title: 'Login',
        types: ['title', 'small', 'label', 'link'],
    },
    'pointsAndPin': {
        title: 'Points & Pin',
        types: undefined,
    },
    'tabs': {
        title: 'Tabs',
        types: ['active', 'inactive'],
    },
    'discountCard': {
        title: 'Discount Cards',
        types: ['title', 'small', 'description'],
    },
    'messageCard': {
        title: 'Messages Cards',
        types: ['small', 'description'],
    },
    'orderHistory': {
        title: 'Order History Collapse',
        types: ['description'],
    },
    'orderHistoryItem': {
        title: 'Order History Item',
        types: ['title', 'description'],
    },
    'profile': {
        title: 'Profile',
        types: ['title', 'description'],
    },
    'reviewPage': {
        title: 'Social',
        types: ['title', 'description', 'link'],
    },
    'faqs': {
        title: 'FAQs',
        types: ['title', 'description'],
    },

}


export default function TypographyTab({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }: AppSettingsTabProps) {

    return (<>
        <AppSettingsStoreSelector {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        <Break air v={10} />
        <Break air v={20} />
        <TypographyConfig {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props, showTypes: ['title', 'description', 'link'] }} />

        <Break />
        <Break air v={20} />
        <b>EDIT BY PAGE</b>

        {Object.keys(dirs).map((dir) => <CCollapse key={dir}  {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, dir, ...props }} />)}


    </>)
}
interface TypoConfig extends AppSettingsTabProps {
    dir?: string
    showTypes?: any[]
}

function CCollapse({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, dir, ...props }: TypoConfig) {

    const [open, setOpen] = useState(false);

    if (!dir || !dirs[dir]) return <></>

    const showTypes = dirs[dir]?.types

    return <>
        <div style={{ margin: '10px 0px', border: '1px solid #E6E8F0', borderRadius: 6 }}>
            <div style={{ padding: '10px', cursor: 'pointer', display: 'flex', justifyContent: 'space-between', background: 'rgba(10,35,56,0.05)', borderRadius: '6px 6px 0px 0px' }} onClick={() => setOpen(!open)}>
                <Space>
                    <b style={{ fontWeight: 600 }}>{dirs[dir].title}</b>
                </Space>
                <Space style={{ marginRight: 10 }}>
                    {open ? <DownOutlined style={{ fontSize: '.75rem' }} /> : <RightOutlined style={{ fontSize: '.75rem' }} />}
                </Space>
            </div>

            {open && <div style={{ borderBottom: '1px solid #E6E8F0', marginBottom: 8 }} />}


            {open && <div style={{ padding: '0px 10px' }}>
                <TypographyConfig {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, showTypes, dir, ...props }} />
            </div>}
        </div>
    </>

}

function TypographyConfig({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, dir, ...props }: TypoConfig) {

    const getURL = (url: string) => `config${dir ? '/' + dir : ''}/text/${url}`
    const inputStyle = { display: 'flex', alignItems: 'center', borderRadius: 6, height: '38px' } as CSSProperties
    const rowStyle = { display: 'flex', marginBottom: 30, marginTop: 20 } as CSSProperties
    const types: any = (props.showTypes || ['title', 'description', 'small'])
    const a = types.includes('title'), b = types.includes('description'), c = types.includes('small'),
        d = types.includes('label'), e = types.includes('link'), f = types.includes('active'), g = types.includes('inactive')


    return <>
        {a && <>
            <b>TITLE</b>
            <Row style={rowStyle}>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Color </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('title/color'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Size </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('title/fontSize'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('title/fontWeight'), 'custom', state.storeID != '-1')} />
                </div>
            </Row>
            <Break />
            <Break air v={20} />
        </>}
        {b && <>
            <b>DESCRIPTION</b>
            <Row style={rowStyle}>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Color </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('description/color'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Size </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('description/fontSize'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('description/fontWeight'), 'custom', state.storeID != '-1')} />
                </div>
            </Row>
            <Break />
            <Break air v={20} />
        </>}
        {c && <>
            <b>SMALL</b>
            <Row style={rowStyle}>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Color </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('small/color'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Size </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('small/fontSize'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('small/fontWeight'), 'custom', state.storeID != '-1')} />
                </div>
            </Row>
        </>}
        {d && <>
            <b>LABEL</b>
            <Row style={rowStyle}>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Color </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('label/color'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Size </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('label/fontSize'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('label/fontWeight'), 'custom', state.storeID != '-1')} />
                </div>
            </Row>
        </>}
        {e && <>
            <b>LINK</b>
            <Row style={rowStyle}>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Color </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('link/color'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Size </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('link/fontSize'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('link/fontWeight'), 'custom', state.storeID != '-1')} />
                </div>
            </Row>
        </>}
        {f && <>
            <b>ACTIVE TITLE</b>
            <Row style={rowStyle}>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Color </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('active/color'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Size </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('active/fontSize'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('active/fontWeight'), 'custom', state.storeID != '-1')} />
                </div>
            </Row>
        </>}
        {g && <>
            <b>INACTIVE TITLE</b>
            <Row style={rowStyle}>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Color </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('inactive/color'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Size </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('inactive/fontSize'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('inactive/fontWeight'), 'custom', state.storeID != '-1')} />
                </div>
            </Row>
        </>}
    </>
}