import { DownOutlined, InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Input, Row, Space, Switch, Tabs } from 'antd';
import React, { CSSProperties, useEffect, useState } from 'react';

import { colors } from '../../../../helpers/allStyles';
import utils from '../../../../helpers/utils';
import { UserSettingsData, UserSettingsToggles } from '@/legacy-types'
import Break from '../../../Break';
import HoverQuestion from '../../../HoverQuestion';
import AppSettingsStoreSelector from '../components/StoreSelector';
import { AppSettingsTabProps } from '../NativeAppSettings';
import { hoverStyle } from './StylingTab';
import TextArea from 'antd/lib/input/TextArea';

export default function MiscTab({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }: AppSettingsTabProps) {
	const { settings } = state;
	const verbiage = state.settings.app?.verbiage;

	const updateToggle = (toggle: string, value: boolean) => {
		const byStore = state.settings.byStore || {};
		const content =
			!state.storeID || state.storeID == '-1'
				? { toggles: { ...settings?.toggles, [toggle]: value } }
				: {
						byStore: {
							...byStore,
							[state.storeID]: {
								...byStore[state.storeID],
								toggles: {
									...byStore[state.storeID]?.toggles,
									[toggle]: value,
								},
							},
						},
					};
		setState({ settings: { ...state.settings, ...content } });
	};

	const updateData = (d: string, value: string | string[]) => {
		const byStore = state.settings.byStore || {};
		const content =
			!state.storeID || state.storeID == '-1'
				? { data: { ...settings?.data, [d]: value } }
				: {
						byStore: {
							...byStore,
							[state.storeID]: {
								...byStore[state.storeID],
								data: {
									...byStore[state.storeID]?.data,
									[d]: value,
								},
							},
						},
					};
		setState({ settings: { ...state.settings, ...content } });
	};

	const getTogglesValue = (type: keyof UserSettingsToggles) => {
		const byStore = state.settings.byStore || {};
		if (state.storeID && state.storeID != '-1') return (byStore[state.storeID]?.toggles || {})[type] || false;
		return (state.settings?.toggles || {})[type];
	};

	const getDataValue = (type: keyof UserSettingsData) => {
		const byStore = state.settings.byStore || {};
		if (state.storeID && state.storeID != '-1') return (byStore[state.storeID]?.data || {})[type] || '';
		return (state.settings?.data || {})[type];
	};

	useEffect(() => {
		if (verbiage?.rafShareText && !verbiage?.rafShareText?.includes('{referLink}')) {
			utils.showErr('RAF share text must include {referLink}');
		}
	}, [verbiage?.rafShareText]);

	const hoverStyle = { fontSize: '.75rem', color: colors.text.lighterer() };
	return (
		<>
			<Tabs
				className="interiorTabs"
				activeKey={state.subTab || 'verbiage'}
				style={{ flex: 1 }}
				onChange={(tab) => changeTab(tab)}
			>
				<Tabs.TabPane
					tab="Verbiage"
					key="verbiage"
					style={{ overflowX: 'hidden', maxHeight: 'calc(100vh - 250px)', scrollbarWidth: 'thin', paddingRight: 5 }}
				>
					<AppSettingsStoreSelector {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
					<Break
						air
						v={10}
					/>
					<b>VERBIAGE</b>
					<Break
						air
						v={20}
					/>
					<Row style={{ justifyContent: 'space-between', marginBottom: 30 }}>
						<div style={{ width: '48%' }}>
							<>
								<span style={{ color: '#8c8c8c', marginRight: 5 }}>Address Field Label</span>
								<HoverQuestion
									style={hoverStyle}
									title="Change the label of the address field within the sign up form"
								/>
								<Break air />
								<Input
									value={getDataValue('addrLabel')}
									onChange={({ target }) => updateData('addrLabel', target.value)}
									style={{ width: '100%', borderRadius: 6 }}
									placeholder="Address or postal code"
								/>
								<br />
								<small style={{ color: '#191919' }}>
									<InfoCircleOutlined /> Often used in Canada{' '}
								</small>
							</>
							<Break
								air
								v="15"
							/>
							<Break
								air
								v="15"
							/>
						</div>
						<div style={{ width: '48%' }}>
							<>
								<span style={{ color: '#8c8c8c', marginRight: 5 }}>Points reference word</span>
								<HoverQuestion
									style={hoverStyle}
									title='Chance all references to "points" to another word'
								/>
								<Break air />
								<Input
									value={getDataValue('pointsAlias')}
									onChange={({ target }) => updateData('pointsAlias', target.value)}
									style={{ width: '100%', borderRadius: 6 }}
									placeholder="points"
								/>
							</>
							{settings?.hasNewSkin && (
								<>
									<Break
										air
										v="15"
									/>
								</>
							)}
						</div>
					</Row>

					{settings?.hasNewSkin && (
						<>
							<CCollapse
								defaultOpen
								{...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }}
								title="Login"
								children={
									<>
										<div style={{ gridColumn: 'span 1' }}>
											<Space>
												<span style={{ color: '#8c8c8c', marginRight: 5 }}>Main Login screen text</span>
												<HoverQuestion
													style={hoverStyle}
													title="Change the wording of the main screen login text"
												/>
											</Space>
											<Break air />
											<TextArea
												{...bnd('verbiage/mainLoginText', '', state.storeID != '-1')}
												style={{ width: '100%', borderRadius: 6 }}
												placeholder={`Don't have a password yet?`}
											/>
										</div>
										<div style={{ gridColumn: 'span 1' }}>
											<Space>
												<span style={{ color: '#8c8c8c', marginRight: 5 }}>Main Signup link text</span>
												<HoverQuestion
													style={hoverStyle}
													title="Change the wording of the main screen signup link text"
												/>
											</Space>
											<Break air />
											<TextArea
												{...bnd('verbiage/signupText', '', state.storeID != '-1')}
												style={{ width: '100%', borderRadius: 6 }}
												placeholder={`Sign up now`}
											/>
										</div>
										<div style={{ gridColumn: 'span 1' }}>
											<Space>
												<span style={{ color: '#8c8c8c', marginRight: 5 }}>Legacy create password text</span>
												<HoverQuestion
													style={hoverStyle}
													title="Change the text on the verification login screen"
												/>
											</Space>
											<Break air />
											<TextArea
												{...bnd('verbiage/createPasswordText', '', state.storeID != '-1')}
												style={{ width: '100%', borderRadius: 6 }}
												placeholder={'After you create a password below for your account we will send you a verification code to the phone or email you provide.'}
											/>
										</div>
										<div style={{ gridColumn: 'span 1' }}>
											<Space>
												<span style={{ color: '#8c8c8c', marginRight: 5 }}>Forgot password text</span>
												<HoverQuestion
													style={hoverStyle}
													title="Change the text on the Forgot password login screen"
												/>
											</Space>
											<Break air />
											<TextArea
												{...bnd('verbiage/forgotPasswordText', '', state.storeID != '-1')}
												style={{ width: '100%', borderRadius: 6 }}
												placeholder={`Enter your mobile phone or email associated with your account and we'll send you a link to reset it.`}
											/>
										</div>
										{settings.app?.requiredSignupFields?.includes('ageGate') && (
											<>
												<div style={{ gridColumn: 'span 1' }}>
													<Space>
														<span style={{ color: '#8c8c8c', marginRight: 5 }}>Age verification text</span>
														<HoverQuestion
															style={hoverStyle}
															title="Change the text on the age verification checkbox"
														/>
													</Space>
													<Break air />
													<TextArea
														{...bnd('verbiage/ageGateText', '', state.storeID != '-1')}
														style={{ width: '100%', borderRadius: 6 }}
														placeholder={`By checking this box, you confirm that you are at least 21 years of age.`}
													/>
												</div>
											</>
										)}
									</>
								}
							/>
							<CCollapse
								defaultOpen
								{...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }}
								title="Home"
								children={
									<>
										<div style={{ gridColumn: 'span 1' }}>
											<Space>
												<span style={{ color: '#8c8c8c', marginRight: 5 }}>Rewards Tab Title</span>
												<HoverQuestion
													style={hoverStyle}
													title='Change the wording of the "My Rewards" tab on the home page'
												/>
											</Space>
											<Break air />
											<Input
												{...bnd('rewardTabTitle', '', state.storeID != '-1')}
												style={{ width: '100%', borderRadius: 6 }}
												placeholder="My Rewards"
											/>
										</div>
										<div style={{ gridColumn: 'span 1' }}>
											<Space>
												<span style={{ color: '#8c8c8c', marginRight: 5 }}>Message Tab Title</span>
												<HoverQuestion
													style={hoverStyle}
													title='Change the wording of the "Latest Updates" tab on the home page'
												/>
											</Space>
											<Break air />
											<Input
												{...bnd('messageTabTitle', '', state.storeID != '-1')}
												style={{ width: '100%', borderRadius: 6 }}
												placeholder="Latest Updates"
											/>
										</div>
										<div style={{ gridColumn: 'span 1' }}>
											<Space>
												<span style={{ color: '#8c8c8c', marginRight: 5 }}>Recent Purchases Label</span>
												<HoverQuestion
													style={hoverStyle}
													title="Change the wording for the label above the order history feed"
												/>
											</Space>
											<Break air />
											<Input
												{...bnd('verbiage/recentPurchases', '', state.storeID != '-1')}
												style={{ width: '100%', borderRadius: 6 }}
												placeholder="Dig your recent purchases?"
											/>
										</div>
										<div style={{ gridColumn: 'span 1' }}>
											<Space>
												<span style={{ color: '#8c8c8c', marginRight: 5 }}>Recent Purchases Extra</span>
												<HoverQuestion
													style={hoverStyle}
													title="Change the wording for the extra label above the order history feed"
												/>
											</Space>
											<Break air />
											<Input
												{...bnd('verbiage/recentPurchasesExtra', '', state.storeID != '-1')}
												style={{ width: '100%', borderRadius: 6 }}
												placeholder="Rate these 6:"
											/>
										</div>
										<div style={{ gridColumn: 'span 1' }}>
											<Space>
												<span style={{ color: '#8c8c8c', marginRight: 5 }}>Popular at this store</span>
												<HoverQuestion
													style={hoverStyle}
													title='Change the wording for the "Popular at this store" label above the popular products feed'
												/>
											</Space>
											<Break air />
											<Input
												{...bnd('verbiage/popularAtThisStore', '', state.storeID != '-1')}
												style={{ width: '100%', borderRadius: 6 }}
												placeholder="Popular at this store:"
											/>
										</div>
									</>
								}
							/>
							<CCollapse
								defaultOpen
								{...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }}
								title="Shop"
								children={
									<>
										<div style={{ gridColumn: 'span 1' }}>
											<Space>
												<span style={{ color: '#8c8c8c', marginRight: 5 }}>Popular Products Tab Title</span>
												<HoverQuestion
													style={hoverStyle}
													title='Change the wording of the "Popular" tab on the shop page'
												/>
											</Space>
											<Break air />
											<Input
												{...bnd('popularTabTitle', '', state.storeID != '-1')}
												style={{ width: '100%', borderRadius: 6 }}
												placeholder="Popular"
											/>
										</div>
										<div style={{ gridColumn: 'span 1' }}>
											<Space>
												<span style={{ color: '#8c8c8c', marginRight: 5 }}>All Products Tab Title</span>
												<HoverQuestion
													style={hoverStyle}
													title='Change the wording of the "All Products" tab on the shop page'
												/>
											</Space>
											<Break air />
											<Input
												{...bnd('allProductsTabTitle', '', state.storeID != '-1')}
												style={{ width: '100%', borderRadius: 6 }}
												placeholder="All Products"
											/>
										</div>
										<div style={{ gridColumn: 'span 1' }}>
											<Space>
												<span style={{ color: '#8c8c8c', marginRight: 5 }}>Shop Points Display Alias</span>
												<HoverQuestion
													style={hoverStyle}
													title='Change the wording of the "X Points Available" text in the shop page'
												/>
											</Space>
											<Break air />
											<Input
												{...bnd('pointsPillAlias', '', state.storeID != '-1')}
												style={{ width: '100%', borderRadius: 6 }}
												placeholder="Points Available"
											/>
										</div>
									</>
								}
							/>
						</>
					)}

					<CCollapse
						defaultOpen
						{...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }}
						title="Social"
						children={
							<>
								<div style={{ gridColumn: 'span 1' }}>
									<Space>
										<span style={{ color: '#8c8c8c', marginRight: 5 }}>RAF top title</span>
										<HoverQuestion
											style={hoverStyle}
											title="Change the first title text on the social tab"
										/>
									</Space>

									<Break air />
									<Input
										{...bnd('verbiage/rafTopTitle', '', state.storeID != '-1')}
										style={{ width: '100%', borderRadius: 6 }}
										placeholder="Refer a Friend"
									/>
								</div>
								<div style={{ gridColumn: 'span 1' }}>
									<Space>
										<span style={{ color: '#8c8c8c', marginRight: 5 }}>RAF top paragraph</span>
										<HoverQuestion
											style={hoverStyle}
											title="Change the first paragraph text on the social tab"
										/>
									</Space>
									<Break air />
									<Input
										{...bnd('verbiage/rafTopParagraph', '', state.storeID != '-1')}
										style={{ width: '100%', borderRadius: 6 }}
										placeholder="Unlock access for your friends with your personal link."
									/>
								</div>
								<div style={{ gridColumn: 'span 1' }}>
									<Space>
										<span style={{ color: '#8c8c8c', marginRight: 5 }}>RAF bottom title</span>
										<HoverQuestion
											style={hoverStyle}
											title="Change the second title text on the social tab"
										/>
									</Space>
									<Break air />
									<Input
										{...bnd('verbiage/rafBottomTitle', '', state.storeID != '-1')}
										style={{ width: '100%', borderRadius: 6 }}
										placeholder="Leave a Review"
									/>
								</div>
								<div style={{ gridColumn: 'span 1' }}>
									<Space>
										<span style={{ color: '#8c8c8c', marginRight: 5 }}>RAF bottom paragraph</span>
										<HoverQuestion
											style={hoverStyle}
											title="Change the second paragraph text on the social tab"
										/>
									</Space>
									<Break air />
									<Input
										{...bnd('verbiage/rafBottomParagraph', '', state.storeID != '-1')}
										style={{ width: '100%', borderRadius: 6 }}
										placeholder="We deeply value your business and hope to connect with you across our community channels. Your feedback is highly appreciated and your responses will help us serve you even better in the future."
									/>
								</div>
								<div style={{ gridColumn: 'span 1' }}>
									<Space>
										<span style={{ color: '#8c8c8c', marginRight: 5 }}>RAF share text</span>
										<HoverQuestion
											style={hoverStyle}
											title="Change the share my code text on the social tab, must use {referLink} to include the link, you can optionally add {storeName} as well"
										/>
									</Space>
									<Break air />
									<Input
										{...bnd('verbiage/rafShareText', '', state.storeID != '-1')}
										style={{ width: '100%', borderRadius: 6 }}
										placeholder="Use my refer a friend code to get access to {storeName} for exclusive deals, updates and more. {referLink}"
									/>
								</div>
							</>
						}
					/>

					{settings?.hasNewSkin && (
						<>
							<CCollapse
								defaultOpen
								{...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }}
								title="Profile"
								children={
									<>
										<div style={{ gridColumn: 'span 1' }}>
											<Space>
												<span style={{ color: '#8c8c8c', marginRight: 5 }}>Profile Tab Title</span>
												<HoverQuestion
													style={hoverStyle}
													title='Change the wording of the "Profile" tab on the profile page'
												/>
											</Space>
											<Break air />
											<Input
												{...bnd('profileTabTitle', '', state.storeID != '-1')}
												style={{ width: '100%', borderRadius: 6 }}
												placeholder="Profile"
											/>
										</div>
										<div style={{ gridColumn: 'span 1' }}>
											<Space>
												<span style={{ color: '#8c8c8c', marginRight: 5 }}>FAQs Tab Title</span>
												<HoverQuestion
													style={hoverStyle}
													title='Change the wording of the "FAQs" tab on the profile page'
												/>
											</Space>
											<Break air />
											<Input
												{...bnd('faqsTabTitle', '', state.storeID != '-1')}
												style={{ width: '100%', borderRadius: 6 }}
												placeholder="FAQs"
											/>
										</div>
									</>
								}
							/>
						</>
					)}
				</Tabs.TabPane>
				<Tabs.TabPane
					tab="Discount Redemption Settings"
					key="redemption"
					style={{ overflowX: 'hidden', maxHeight: 'calc(100vh - 250px)', scrollbarWidth: 'thin', paddingRight: 5 }}
				>
					<AppSettingsStoreSelector {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
					<Break
						air
						v={10}
					/>
					<b>VERBIAGE</b>
					<Break
						air
						v={20}
					/>
					<Row style={{ justifyContent: 'space-between', marginBottom: 30 }}>
						<div style={{ width: '48%' }}>
							<span style={{ color: '#8c8c8c', marginRight: 5 }}>Redeem at a sales counter text</span>
							<HoverQuestion
								style={hoverStyle}
								title='Change the text for "Redeem at a sales counter" text.'
							/>
							<Break air />
							<Input
								value={getDataValue('redeemTitle')}
								onChange={({ target }) => updateData('redeemTitle', target.value)}
								style={{ width: '100%', borderRadius: 6 }}
								placeholder="Redeem at sales counter"
							/>
							<br />
							<small style={{ color: '#191919' }}>(preview not available)</small>
						</div>
						<div style={{ width: '48%' }}>
							<span style={{ color: '#8c8c8c', marginRight: 5 }}>Redeem in-store text</span>
							<HoverQuestion
								style={hoverStyle}
								title='Change the text for "Redeem in-store" button.'
							/>
							<Break air />
							<Input
								value={getDataValue('redeemInStoreText')}
								onChange={({ target }) => updateData('redeemInStoreText', target.value)}
								style={{ width: '100%', borderRadius: 6 }}
								placeholder="Redeem in-store"
							/>
							<br />
							<small style={{ color: '#191919' }}>(preview not available)</small>
						</div>
						<div style={{ width: '48%' }}>
							<span style={{ color: '#8c8c8c', marginRight: 5 }}>Redeem text</span>
							<HoverQuestion
								style={hoverStyle}
								title='Change the text for "REDEEM" button on deals.'
							/>
							<Break air />
							<Input
								{...bnd('redeemButtonText')}
								style={{ width: '100%', borderRadius: 6 }}
								placeholder=""
							/>
						</div>
					</Row>
					<Break />
					<Break
						air
						v={20}
					/>
					<b>TOGGLES</b>
					<Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
						<div style={{ width: '48%' }}>
							<Switch
								size="small"
								style={{ minWidth: 36 }}
								onChange={(v) => updateToggle('hideBar', v)}
								checked={getTogglesValue('hideBar')}
							/>
							<span style={{ color: '#8c8c8c', marginRight: 5, marginLeft: 5 }}>Hide Transparent Bar</span>
							<HoverQuestion
								style={hoverStyle}
								title="Hide transparent bar from discount page & signup form"
							/>
							<br />
							<small style={{ color: '#191919' }}>(preview not available)</small>

							<Break
								air
								v="10"
							/>

							<Switch
								size="small"
								style={{ minWidth: 36 }}
								onChange={(v) => updateToggle('hideLogo', v)}
								checked={getTogglesValue('hideLogo')}
							/>
							<span style={{ color: '#8c8c8c', marginRight: 5, marginLeft: 5 }}>Hide logo</span>
							<HoverQuestion
								style={hoverStyle}
								title="Hide logo from discount redemption page & signup forms"
							/>
							<br />
							<small style={{ color: '#191919' }}>(preview not available)</small>

							<Break
								air
								v="10"
							/>

							<Switch
								size="small"
								style={{ minWidth: 36 }}
								onChange={(v) => updateToggle('hideName', v)}
								checked={getTogglesValue('hideName')}
							/>
							<span style={{ color: '#8c8c8c', marginRight: 5, marginLeft: 5 }}>Hide name</span>
							<HoverQuestion
								style={hoverStyle}
								title="Hide company name from discount redemption page"
							/>
							<br />
							<small style={{ color: '#191919' }}>(preview not available)</small>
							<Break
								air
								v="10"
							/>

							<Switch
								size="small"
								style={{ minWidth: 36 }}
								onChange={(v) => updateToggle('squareLogo', v)}
								checked={getTogglesValue('squareLogo')}
							/>
							<span style={{ color: '#8c8c8c', marginRight: 5, marginLeft: 5 }}>Square Logo</span>
							<HoverQuestion
								style={hoverStyle}
								title="Use square logo for discount redemption page"
							/>
							<br />
							<small style={{ color: '#191919' }}>(preview not available)</small>
						</div>
						<div style={{ width: '48%' }}>
							<Switch
								size="small"
								style={{ minWidth: 36 }}
								checked={getTogglesValue('disableRedeemInStore')}
								onChange={(v) => updateToggle('disableRedeemInStore', v)}
							/>
							<span style={{ color: '#8c8c8c', marginRight: 5, marginLeft: 5 }}>Ask staff to redeem</span>
							<HoverQuestion
								style={hoverStyle}
								title='Disable the "Redeem in-store" button when viewing a discounts page showing "Ask staff to redeem" instead'
							/>
							<br />
							<small style={{ color: '#191919' }}>(preview not available)</small>
							<Break
								air
								v="10"
							/>
							<Switch
								size="small"
								style={{ minWidth: 36 }}
								checked={getTogglesValue('hideRedeemInStore')}
								onChange={(v) => updateToggle('hideRedeemInStore', v)}
							/>
							<span style={{ color: '#8c8c8c', marginRight: 5, marginLeft: 5 }}>Disable redeem buttons</span>
							<HoverQuestion
								style={hoverStyle}
								title={`Disable the redemption button completely showing nothing in it's place.`}
							/>
							<br />
							<small style={{ color: '#191919' }}>(preview not available)</small>
						</div>
					</Row>
				</Tabs.TabPane>
			</Tabs>
		</>
	);
}

interface CCollapseProps extends AppSettingsTabProps {
	title: React.ReactNode;
	children?: React.ReactNode;
	style?: CSSProperties;
	defaultOpen?: boolean;
}

export function CCollapse({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }: CCollapseProps) {
	const [open, setOpen] = useState(props?.defaultOpen || false);

	return (
		<>
			<div style={{ margin: '10px 0px', border: '1px solid #E6E8F0', borderRadius: 6 }}>
				<div
					style={{
						padding: '10px',
						cursor: 'pointer',
						display: 'flex',
						justifyContent: 'space-between',
						background: 'rgba(10,35,56,0.05)',
						borderRadius: '6px 6px 0px 0px',
					}}
					onClick={() => setOpen(!open)}
				>
					<Space>
						<b style={{ fontWeight: 600 }}>{props.title}</b>
					</Space>
					<Space style={{ marginRight: 10 }}>{open ? <DownOutlined style={{ fontSize: '.75rem' }} /> : <RightOutlined style={{ fontSize: '.75rem' }} />}</Space>
				</div>

				{open && (
					<div
						style={{
							padding: 10,
							display: 'grid',
							gridTemplateColumns: 'repeat(2, 1fr)',
							gridGap: 20,
							...props?.style,
						}}
					>
						{/* <Row style={{ display: 'flex', marginBottom: 30, marginTop: 20 }}> */}
						{props.children}
						{/* </Row> */}
					</div>
				)}
			</div>
		</>
	);
}
