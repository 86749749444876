import React from 'react';

import { AppSettingsTabProps } from '../../NativeAppSettings';
import { hoverStyle } from '../StylingTab';
import HoverQuestion from '../../../../HoverQuestion';
import Break from '../../../../Break';
import { InputNumber, Row } from 'antd';
import HexPicker from '../../../../global/HexPicker';
import AppSettingsStoreSelector from '../../components/StoreSelector';

export default function SectionsTab({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }: AppSettingsTabProps) {

    return (<>
        <AppSettingsStoreSelector {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        <Row style={{ justifyContent: 'space-between', paddingTop: 10, marginBottom: 30 }}>
            <div style={{ width: '30%' }}>
                <b>MAIN BACKGROUND</b>
                <Break air v={20} />
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The main background color of the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('mainBGColor', '#fff', state.storeID != '-1')} />
            </div>
            <div style={{ width: '30%' }}>
                <b>TOP BAR BACKGROUND</b>
                <Break air v={20} />
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The background color of the app's tab title bar" />

                <Break air />
                <HexPicker {...cbnd('topBar/backgroundColor', '#fff', state.storeID != '-1')} />
            </div>
            <div style={{ width: '30%' }}>
                <b>NAVBAR BACKGROUND</b>
                <Break air v={20} />
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The background color of the app's navigation bar" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('navBackgroundColor', '#fff', state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />
        <b>INTERNAL CONTAINERS</b>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '30%' }}>
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The background color of the internal sections" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('containerInternal/backgroundColor', '#fff', state.storeID != '-1')} />
            </div>
            <div style={{ width: '30%' }}>
                <span style={{ color: '#8c8c8c' }}>Border Color </span>
                <HoverQuestion style={hoverStyle} title="The border color of the app's sections" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('borderColor', '#6CD9A0', state.storeID != '-1')} />
            </div>
            <div style={{ width: '30%' }}>
                <span style={{ color: '#8c8c8c' }}>Border Radius </span>
                <HoverQuestion style={hoverStyle} title="The roundness of the internal section corners" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={1} max={25} {...nbnd('containerInternal/borderRadius', 10, state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />
        <Row style={{ justifyContent: 'space-between', marginBottom: 30 }}>
            <div style={{ width: '30%' }}>
                <b>MESSAGES BACKGROUND</b>
                <Break air v={20} />
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The background color for individual messages" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('msgBG', '#FFF', state.storeID != '-1')} />
            </div>
        </Row>
        <Break air v={20} />
        <b>MESSAGE TEXT</b>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="message text color on messages" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('messageColor', '', state.storeID != '-1')} />
            </div>
        </Row>
    </>)
}
