import React from 'react';

import { AppSettingsTabProps } from '../../NativeAppSettings';

import { hoverStyle } from '../StylingTab';
import HoverQuestion from '../../../../HoverQuestion';
import Break from '../../../../Break';
import { InputNumber, Row } from 'antd';
import HexPicker from '../../../../global/HexPicker';
import AppSettingsStoreSelector from '../../components/StoreSelector';

export default function ButtonsTab({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }: AppSettingsTabProps) {

    return (<>
        <AppSettingsStoreSelector {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        <Break air v={10} />
        <b>MAIN BUTTON</b>
        <Break air v={20} />
        <Row style={{ justifyContent: 'space-between', marginBottom: 30 }}>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The main color of buttons in the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('button/backgroundColor', '#34B87D', state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Border Color </span>
                <HoverQuestion style={hoverStyle} title="The border color of buttons in the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('button/borderColor', '#34B87D', state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Border Radius </span>
                <HoverQuestion style={hoverStyle} title="The roundness of the button corners" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={1} max={25} {...nbnd('button/borderRadius', 8, state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Text Color </span>
                <HoverQuestion style={hoverStyle} title="The text color of the buttons" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('buttonText/color', '', state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                <HoverQuestion style={hoverStyle} title="The overall thickness of the button text fonts" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={100} max={800} {...nbnd('buttonText/fontWeight', 600, state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Size </span>
                <HoverQuestion style={hoverStyle} title="The font size of the button text" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={8} max={50} {...nbnd('buttonText/fontSize', 14, state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />
        <b style={{ color: '#7B7B80' }}>Not available for preview:</b>
        <Break air v={20} />
        <b>GHOST BUTTON</b>
        <Break air v={20} />
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The main color of ghost buttons in the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('buttonGhost/backgroundColor', '#fff', state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Border Color </span>
                <HoverQuestion style={hoverStyle} title="The border color of ghost button in the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('buttonGhost/borderColor', '#34B87D', state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Border Radius </span>
                <HoverQuestion style={hoverStyle} title="The roundness of the ghost button corners" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6 }} size="small" min={1} max={25} {...nbnd('buttonGhost/borderRadius', 8, state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Text Color </span>
                <HoverQuestion style={hoverStyle} title="The text color of the ghost buttons" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('buttonGhostText/color', '#34B87D', state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                <HoverQuestion style={hoverStyle} title="The overall thickness of the ghost button fonts" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={100} max={800} {...nbnd('buttonGhostText/fontWeight', 600, state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Size </span>
                <HoverQuestion style={hoverStyle} title="The font size of the ghost button text" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={8} max={50} {...nbnd('buttonGhostText/fontSize', 18, state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />
        <b>DANGER BUTTON</b>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Color</span>
                <HoverQuestion style={hoverStyle} title="The main color of danger buttons in the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('buttonDanger/backgroundColor', '#FF4D7E', state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Border Color </span>
                <HoverQuestion style={hoverStyle} title="The border color of danger buttons in the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('buttonDanger/borderColor', '#FF4D7E', state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Border Radius </span>
                <HoverQuestion style={hoverStyle} title="The roundness of the danger button corners" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={1} max={25} {...nbnd('buttonDanger/borderRadius', 8, state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Text Color </span>
                <HoverQuestion style={hoverStyle} title="The text color of the danger buttons" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('buttonDangerText/color', '#fff', state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                <HoverQuestion style={hoverStyle} title="The overall thickness of the danger button fonts" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={100} max={800} {...nbnd('buttonDangerText/fontWeight', 600, state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Size </span>
                <HoverQuestion style={hoverStyle} title="The font size of the danger button text" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={8} max={50} {...nbnd('buttonDangerText/fontSize', 18, state.storeID != '-1')} />
            </div>
        </Row>
    </>)
}
