import { Input, InputNumber, Row, Space } from 'antd';
import React, { CSSProperties, useState } from 'react';

import Break from '../../../../Break';
import HoverQuestion from '../../../../HoverQuestion';
import HexPicker from '../../../../global/HexPicker';
import AppSettingsStoreSelector from '../../components/StoreSelector';
import { AppSettingsTabProps } from '../../NativeAppSettings';
import { hoverStyle } from '../StylingTab';
import { DownOutlined, GiftTwoTone, RightOutlined, SettingOutlined } from '@ant-design/icons';
import GTooltip from '../../../../global/GTooltip';
import ResetInput from '../../components/ResetInput';

export const dirs: any = {
    // 'login': {
    //     title: 'Login',
    //     types: undefined,
    // },
    // 'profile': {
    //     title: 'Profile Page',
    //     types: undefined,
    // },
    // 'faqs': {
    //     title: 'FAQS',
    //     types: undefined,
    // },
    'messageCard': {
        title: 'Messages Card',
        types: ['button'],
    },
    'discountCard': {
        title: 'Discount Button',
        types: ['button'],
    },
    'dynamicContent': {
        title: 'Dynamic Content',
        types: ['button'],
    },
    'orderHistoryItem': {
        title: 'Order History Item',
        types: ['button'],
    },
    'reviewPage': {
        title: 'Social',
        types: ['button'],
    },
}



export default function NewButtonsTab({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }: AppSettingsTabProps) {

    return (<>
        <AppSettingsStoreSelector {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        <Break air v={10} />
        <Break air v={20} />
        <ButtonConfig {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />

        <Break />
        <Break air v={20} />
        <b>EDIT BY PAGE</b>

        {Object.keys(dirs).map((dir) => <CCollapse key={dir}  {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, dir, ...props }} />)}


    </>)
}

interface TypoConfig extends AppSettingsTabProps {
    dir?: string
    showTypes?: []
}

function CCollapse({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, dir, ...props }: TypoConfig) {

    const [open, setOpen] = useState(false);

    if (!dir || !dirs[dir]) return <></>

    const showTypes = dirs[dir]?.types

    return <>
        <div style={{ margin: '10px 0px', border: '1px solid #E6E8F0', borderRadius: 6 }}>
            <div style={{ padding: '10px', cursor: 'pointer', display: 'flex', justifyContent: 'space-between', background: 'rgba(10,35,56,0.05)', borderRadius: '6px 6px 0px 0px' }} onClick={() => setOpen(!open)}>
                <Space>
                    <b style={{ fontWeight: 600 }}>{dirs[dir].title}</b>
                </Space>
                <Space style={{ marginRight: 10 }}>
                    {open ? <DownOutlined style={{ fontSize: '.75rem' }} /> : <RightOutlined style={{ fontSize: '.75rem' }} />}
                </Space>
            </div>


            {open && <div style={{ padding: '0px 10px' }}>
                <ButtonConfig {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, dir, showTypes, ...props }} />
            </div>}
        </div>
    </>

}

function ButtonConfig({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, dir, ...props }: TypoConfig) {

    const getURL = (url: string) => `config${dir ? '/' + dir : ''}/${url}`
    const inputStyle = { display: 'flex', alignItems: 'center', borderRadius: 6, height: '38px' } as CSSProperties
    const rowStyle = { display: 'flex', marginBottom: 30, marginTop: 20 } as CSSProperties

    const types: any = (props.showTypes || ['button', 'buttonSecondary', 'buttonDanger'])
    const a = types.includes('button'), b = types.includes('buttonSecondary'), c = types.includes('buttonDanger')

    return <>
        {a && <>
            {(b || c) && <b>DEFAULT</b>}
            <Row style={rowStyle}>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Text Color</span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('button/color'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Background</span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('button/backgroundColor'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Border</span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('button/borderColor'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Border Radius</span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <BorderRadiusInput {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} dir={getURL('button')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Size </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('button/fontSize'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('button/fontWeight'), 'custom', state.storeID != '-1')} />
                </div>
            </Row>
        </>}
        {b && <>
            <Break />
            <Break air v={20} />
            <b>SECONDARY</b>
            <Row style={rowStyle}>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Text Color</span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('buttonSecondary/color'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Background</span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('buttonSecondary/backgroundColor'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Border</span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('buttonSecondary/borderColor'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Border Radius</span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <BorderRadiusInput {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} dir={getURL('buttonSecondary')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Size </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('buttonSecondary/fontSize'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('buttonSecondary/fontWeight'), 'custom', state.storeID != '-1')} />
                </div>
            </Row>
        </>}
        {c && <>
            <Break />
            <Break air v={20} />
            <b>DANGER</b>
            <Row style={rowStyle}>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Text Color</span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('buttonDanger/color'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Background</span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('buttonDanger/backgroundColor'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Border</span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>

                    <Break air />
                    <HexPicker reset {...cbnd(getURL('buttonDanger/borderColor'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Border Radius</span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <BorderRadiusInput {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} dir={getURL('buttonDanger')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Size </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('buttonDanger/fontSize'), 'custom', state.storeID != '-1')} />
                </div>
                <div style={{ flex: 1 }}>
                    <Space>
                        <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                        <HoverQuestion style={hoverStyle} title="" />
                    </Space>
                    <Break air />
                    <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('buttonDanger/fontWeight'), 'custom', state.storeID != '-1')} />
                </div>
            </Row>
        </>}
    </>
}

function BorderRadiusInput({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, dir, ...props }: TypoConfig) {
    const getURL = (url: string) => `${dir}/${url}`
    const inputStyle = { display: 'flex', alignItems: 'center', borderRadius: 6, height: '38px' } as CSSProperties
    const [open, setOpen] = useState(false);


    const binder = bnd(dir || '', 'custom', state.storeID != '-1')
    const isNotDefault = binder.value.borderTopLeftRadius || binder.value.borderTopRightRadius || binder.value.borderBottomRightRadius || binder.value.borderBottomLeftRadius

    return <div style={{ paddingRight: 16 }}>
        {open ? <>
            <Space>
                <Input.Group style={{ width: 115 }}>
                    <InputNumber controls={false} style={{ padding: '5px 0px 0px 2px', height: 38, width: '25%', borderRadius: '6px 0px 0px 6px' }} size="small" {...nbnd(getURL('borderTopLeftRadius'), 'custom', state.storeID != '-1')} />
                    <InputNumber controls={false} style={{ padding: '5px 0px 0px 2px', height: 38, width: '25%', borderRadius: 0 }} size="small" {...nbnd(getURL('borderTopRightRadius'), 'custom', state.storeID != '-1')} />
                    <InputNumber controls={false} style={{ padding: '5px 0px 0px 2px', height: 38, width: '25%', borderRadius: 0 }} size="small" {...nbnd(getURL('borderBottomRightRadius'), 'custom', state.storeID != '-1')} />
                    <InputNumber controls={false} style={{ padding: '5px 0px 0px 2px', height: 38, width: '25%', borderRadius: '0px 6px 6px 0px' }} size="small" {...nbnd(getURL('borderBottomLeftRadius'), 'custom', state.storeID != '-1')} />
                </Input.Group>
                {isNotDefault && <i className="fa-regular fa-arrow-rotate-left linked" style={{}} onClick={() => {
                    const { onChange, value, def } = binder
                    delete value.borderTopLeftRadius
                    delete value.borderTopRightRadius
                    delete value.borderBottomRightRadius
                    delete value.borderBottomLeftRadius
                    onChange(value)
                    setOpen(false)
                }} />}
                <GTooltip tooltip='Default Border Options'>
                    <i className="fa-regular fa-border-all linked" style={{}} onClick={() => setOpen(false)} />
                </GTooltip>
            </Space>
        </> : <>
            <Space>
                <ResetInput type='number' style={inputStyle} size="small" {...nbnd(getURL('borderRadius'), 'custom', state.storeID != '-1')} />
                <GTooltip tooltip='Advanced Border Options'>
                    <i className="fa-duotone fa-border-outer linked" style={{}} onClick={() => setOpen(true)} />
                </GTooltip>
            </Space>
        </>}
    </div>
}