import React, { CSSProperties } from 'react'
import { AppSettingsTabProps } from '../../NativeAppSettings'
import AppSettingsStoreSelector from '../../components/StoreSelector'
import Break from '../../../../Break'
import { InputNumber, Row, Space } from 'antd'
import HoverQuestion from '../../../../HoverQuestion'
import HexPicker from '../../../../global/HexPicker'
import { hoverStyle } from '../StylingTab'
import { CCollapse } from '../MiscTab'

export default function GeneralTab({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }: AppSettingsTabProps) {
    const { settings } = state
    const getURL = (url: string) => `config/${url}`
    const inputStyle = { display: 'flex', alignItems: 'center', borderRadius: 6, height: '38px' } as CSSProperties
    const rowStyle = { display: 'flex', marginBottom: 30, marginTop: 20 } as CSSProperties

    return (<>
        <AppSettingsStoreSelector {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        <Break air v={10} />
        <Break air v={20} />
        <b>COLOR PALETTE</b>
        <Row style={rowStyle}>
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Color </span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>

                <Break air />
                <HexPicker reset {...cbnd(getURL('color'), 'custom', state.storeID != '-1')} />
            </div>
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Primary </span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>
                <Break air />
                <HexPicker reset {...cbnd(getURL('primary'), 'custom', state.storeID != '-1')} />
            </div>
            {/* <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Secondary </span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>
                <Break air />
                <HexPicker reset {...cbnd(getURL('secondary'), 'custom', state.storeID != '-1')} />
            </div> */}
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Highlight</span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>
                <Break air />
                <HexPicker reset {...cbnd(getURL('highlight'), 'custom', state.storeID != '-1')} />
            </div>
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Error</span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>
                <Break air />
                <HexPicker reset {...cbnd(getURL('error'), 'custom', state.storeID != '-1')} />
            </div>
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Link</span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>
                <Break air />
                <HexPicker reset {...cbnd(getURL('link'), 'custom', state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />
        <b>HEADER</b>
        <Row style={rowStyle}>
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Color </span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>

                <Break air />
                <HexPicker reset {...cbnd(getURL('header/color'), 'custom', state.storeID != '-1')} />
            </div>
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Primary </span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>
                <Break air />
                <HexPicker reset {...cbnd(getURL('header/primary'), 'custom', state.storeID != '-1')} />
            </div>
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Highlight</span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>
                <Break air />
                <HexPicker reset {...cbnd(getURL('header/highlight'), 'custom', state.storeID != '-1')} />
            </div>
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Address Color</span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>
                <Break air />
                <HexPicker reset {...cbnd(getURL('header/addrColor'), 'custom', state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />
        <b>FOOTER</b>
        <Row style={rowStyle}>
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Color </span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>

                <Break air />
                <HexPicker reset {...cbnd(getURL('footer/color'), 'custom', state.storeID != '-1')} />
            </div>
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Primary </span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>
                <Break air />
                <HexPicker reset {...cbnd(getURL('footer/primary'), 'custom', state.storeID != '-1')} />
            </div>
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Secondary </span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>
                <Break air />
                <HexPicker reset {...cbnd(getURL('footer/secondary'), 'custom', state.storeID != '-1')} />
            </div>
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Highlight</span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>
                <Break air />
                <HexPicker reset {...cbnd(getURL('footer/highlight'), 'custom', state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />
        <b>BODY</b>
        <Row style={rowStyle}>
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Background </span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>

                <Break air />
                <HexPicker reset {...cbnd(getURL('body/backgroundColor'), 'custom', state.storeID != '-1')} />
            </div>
            <div style={{ flex: 1 }}>
                <Space>
                    <span style={{ color: '#8c8c8c' }}>Divider color </span>
                    <HoverQuestion style={hoverStyle} title="" />
                </Space>

                <Break air />
                <HexPicker reset {...cbnd(getURL('dividerColor'), 'custom', state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />

        {settings?.hasNewSkin && <CCollapse
            defaultOpen
            {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }}
            title='Discount Cards'
            style={{ gridTemplateColumns: 'repeat(4, 1fr)', }}
            children={<>
                <div style={{ gridColumn: 'span 1' }}>
                    <Space>
                        <div style={{ flex: 1 }}>
                            <Space>
                                <span style={{ color: '#8c8c8c' }}>Background Color </span>
                                <HoverQuestion style={hoverStyle} title="The background color of the discount progress bar" />
                            </Space>

                            <Break air />
                            <HexPicker reset {...cbnd(getURL('discountCard/progress/primary'), 'custom', state.storeID != '-1')} />
                        </div>
                    </Space>
                </div>
                <div style={{ gridColumn: 'span 1' }}>
                    <Space>
                        <div style={{ flex: 1 }}>
                            <Space>
                                <span style={{ color: '#8c8c8c' }}>Completed Fill Color </span>
                                <HoverQuestion style={hoverStyle} title="The 100% complete progress color of the discount progress bar" />
                            </Space>

                            <Break air />
                            <HexPicker reset {...cbnd(getURL('discountCard/progress/highlight'), 'custom', state.storeID != '-1')} />
                        </div>
                    </Space>
                </div>
            </>}
        />}
        {settings?.hasNewSkin && <CCollapse
            defaultOpen
            {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }}
            title='Message Cards'
            style={{ gridTemplateColumns: 'repeat(4, 1fr)', }}
            children={<>
                <div style={{ gridColumn: 'span 1' }}>
                    <Space>
                        <div style={{ flex: 1 }}>
                            <Space>
                                <span style={{ color: '#8c8c8c' }}>Background Color </span>
                                <HoverQuestion style={hoverStyle} title="The background color of individual messages" />
                            </Space>

                            <Break air />
                            <HexPicker reset {...cbnd(getURL('messageCard/backgroundColor'), 'custom', state.storeID != '-1')} />
                        </div>
                    </Space>
                </div>
            </>}
        />}
        {settings?.hasNewSkin && <CCollapse
            defaultOpen
            {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }}
            title='SOCIAL'
            style={{ gridTemplateColumns: 'repeat(4, 1fr)', }}
            children={<>
                <div style={{ gridColumn: 'span 1' }}>
                    <Space>
                        <div style={{ flex: 1 }}>
                            <Space>
                                <span style={{ color: '#8c8c8c' }}>Container background</span>
                                <HoverQuestion style={hoverStyle} title="" />
                            </Space>

                            <Break air />
                            <HexPicker reset {...cbnd(getURL('reviewPage/container/backgroundColor'), 'custom', state.storeID != '-1')} />
                        </div>
                    </Space>
                </div>
                <div style={{ gridColumn: 'span 1' }}>
                    <Space>
                        <div style={{ flex: 1 }}>
                            <Space>
                                <span style={{ color: '#8c8c8c' }}>Container outline</span>
                                <HoverQuestion style={hoverStyle} title="" />
                            </Space>

                            <Break air />
                            <HexPicker reset {...cbnd(getURL('reviewPage/container/borderColor'), 'custom', state.storeID != '-1')} />
                        </div>
                    </Space>
                </div>

            </>}
        />}
    </>)
}
