import React from 'react';

import { AppSettingsTabProps } from '../../NativeAppSettings';

import { hoverStyle } from '../StylingTab';
import HoverQuestion from '../../../../HoverQuestion';
import Break from '../../../../Break';
import { InputNumber, Row } from 'antd';
import HexPicker from '../../../../global/HexPicker';
import AppSettingsStoreSelector from '../../components/StoreSelector';

export default function TypoIconTabs({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }: AppSettingsTabProps) {

    return (<>
        <AppSettingsStoreSelector {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        <Row style={{ justifyContent: 'space-between', paddingTop: 10, marginBottom: 30 }}>
            <div style={{ width: '20%' }}>
                <b>TAB TITLE</b>
                <Break air v={20} />
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The tab title text color of the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('tabTitle', '#6CD9A0', state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />
        <b>SECTION TITLE</b>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The section title text color of the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('sectionTitle/color', '#000', state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                <HoverQuestion style={hoverStyle} title="The overall thickness of the section title fonts" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={1} max={800} {...nbnd('sectionTitle/fontWeight', 800, state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Size </span>
                <HoverQuestion style={hoverStyle} title="The font size of section titles" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={1} max={25} {...nbnd('sectionTitle/fontSize', 18, state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}></div>{/* placeholder to maintain spacing */}
        </Row>
        <Break />
        <Break air v={20} />
        <b>SUBTITLE</b>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The main text color for subtitles in the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('h2/color', '#6CD9A0', state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                <HoverQuestion style={hoverStyle} title="The overall thickness of the subtitle fonts" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={100} max={800} {...nbnd('h2/fontWeight', 800, state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Size </span>
                <HoverQuestion style={hoverStyle} title="The font size of the subtitles" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={8} max={50} {...nbnd('h2/fontSize', 16, state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}></div>{/* placeholder to maintain spacing */}
        </Row>
        <Break />
        <Break air v={20} />
        <b>MAIN TEXT</b>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The main text color of the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('containerInternal/color', '#000', state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />
        <b>COLLAPSABLE TITLES</b>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The text color of the collapse sections in app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('collapse/color', '#6CD9A0', state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Border Color </span>
                <HoverQuestion style={hoverStyle} title="The border color for collapse sections" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('collapse/borderColor', '#6CD9A0', state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                <HoverQuestion style={hoverStyle} title="The overall thickness of the collapse title fonts" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={100} max={800} {...nbnd('collapse/fontWeight', 600, state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Size </span>
                <HoverQuestion style={hoverStyle} title="The font size of the collapse section titles" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={8} max={50} {...nbnd('collapse/fontSize', 16, state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />
        <b>REFER-A-FRIEND BOX</b>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Link Color </span>
                <HoverQuestion style={hoverStyle} title="The text color of the refer-a-friend box in app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('referLink/color', '#6CD9A0', state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Border Color </span>
                <HoverQuestion style={hoverStyle} title="The border color for refer-a-friend box" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('referbox/borderColor', '#000', state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                <HoverQuestion style={hoverStyle} title="The font weight for refer-a-friend box" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={1} max={200} {...nbnd('referBox/padding', 10, state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Size </span>
                <HoverQuestion style={hoverStyle} title="The font size for refer-a-friend box" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={1} max={25} {...nbnd('referBox/borderRadius', 8, state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />
        <b>NAV ICONS</b>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The main color of the navbar icons in the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('navIcon', '', state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />
        <b>ICONS</b>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The main color of the icons in the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('icon/color', '', state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />
        <b style={{ color: '#7B7B80' }}>Not available for preview:</b>
        <Break air v={20} />
        <b>ERROR TEXT</b>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The main color of the error text in the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('error/color', '#FF4D7E', state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Border Color </span>
                <HoverQuestion style={hoverStyle} title="The border color of the error text" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('error/borderColor', '#FF4D7E', state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Weight </span>
                <HoverQuestion style={hoverStyle} title="The overall thickness of the error text fonts" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={100} max={800} {...nbnd('error/fontWeight', 600, state.storeID != '-1')} />
            </div>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Font Size </span>
                <HoverQuestion style={hoverStyle} title="The font size of the error text" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={1} max={25} {...nbnd('error/fontSize', 16, state.storeID != '-1')} />
            </div>
        </Row>
        <Break />
        <Break air v={20} />
        <b>LINKS</b>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '20%' }}>
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The main color of links in the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('link/color', '#34B87D', state.storeID != '-1')} />
            </div>
        </Row>
    </>)
}
