import { Space, Tabs } from 'antd';
import React, { CSSProperties } from 'react';

import { colors } from '../../../../helpers/allStyles';
import { AppSettingsTabProps } from '../NativeAppSettings';
import BrandingTab from './styling/BrandingTab';
import ButtonsTab from './styling/ButtonsTab';
import InputsTab from './styling/InputsTab';
import SectionsTab from './styling/SectionsTab';
import SkinsTab from './styling/SkinsTab';
import TypoIconTabs from './styling/TypoIconsTab';
import GeneralTab from './styling/GeneralTab';
import TypographyTab from './styling/TypographyTab';
import NewInputsTab from './styling/NewInputsTab';
import NewButtonsTab from './styling/NewButtonsTab';
import GTooltip from '../../../global/GTooltip';



export const hoverStyle = { fontSize: '.75rem', color: colors.text.lighterer() };
export default function StylingTab({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }: AppSettingsTabProps) {
    const { settings } = state, isNewApp = settings?.hasNewSkin

    const tabStyle: CSSProperties = { overflowX: 'hidden', maxHeight: 'calc(100vh - 250px)', scrollbarWidth: 'thin', paddingRight: 5 }

    const tabs: any = {
        branding: {
            label: 'Branding',
            children: <BrandingTab {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        },
        containerStyles: {
            hidden: isNewApp,
            label: 'Sections',
            children: <SectionsTab {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        },
        typoStyles: {
            hidden: isNewApp,
            label: 'Typography & Icons',
            children: <TypoIconTabs {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        },
        buttonStyles: {
            hidden: isNewApp,
            label: 'Buttons',
            children: <ButtonsTab {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        },
        inputStyles: {
            hidden: isNewApp,
            label: 'Inputs',
            children: <InputsTab {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        },
        general: {
            hidden: !isNewApp,
            label: 'General',
            children: <GeneralTab {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        },
        header: {
            label: 'Header',
            children: <SkinsTab {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        },
        typography: {
            hidden: !isNewApp,
            label: 'Typography',
            children: <TypographyTab {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        },
        buttons: {
            hidden: !isNewApp,
            label: 'Buttons',
            children: <NewButtonsTab {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        },
        inputs: {
            hidden: !isNewApp,
            label: 'Inputs',
            children: <NewInputsTab {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        },

    }

    const items = Object.keys(tabs).filter((key) => !tabs[key].hidden).map((key) => ({
        key, ...(tabs[key]), children: <div style={tabStyle}>
            {(tabs[key]).children}
        </div>
    }))


    return (<>
        <Tabs
            style={{ flex: 1 }}
            className='interiorTabs'
            items={items}
            activeKey={state.subTab || 'branding'}
            onChange={(tab) => changeTab(tab)}
        />
    </>)
}
