import React from 'react';

import { AppSettingsTabProps } from '../../NativeAppSettings';

import { hoverStyle } from '../StylingTab';
import HoverQuestion from '../../../../HoverQuestion';
import Break from '../../../../Break';
import { InputNumber, Row } from 'antd';
import HexPicker from '../../../../global/HexPicker';
import AppSettingsStoreSelector from '../../components/StoreSelector';

export default function InputsTab({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }: AppSettingsTabProps) {

    return (<>
        <AppSettingsStoreSelector {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        <Break air v={10} />
        <b style={{ color: '#7B7B80' }}>Not available for preview:</b>
        <Break air v={20} />
        <b>TOGGLES</b>
        <Break air v={20} />
        <Row style={{ justifyContent: 'space-between', marginBottom: 30 }}>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Color </span>
                <HoverQuestion style={hoverStyle} title="The main color for active toggle switches in the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('switch/color', '#0693e3', state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}></div>{/* placeholder to maintain spacing */}
            <div style={{ width: '16%' }}></div>{/* placeholder to maintain spacing */}
            <div style={{ width: '16%' }}></div>{/* placeholder to maintain spacing */}
            <div style={{ width: '16%' }}></div>{/* placeholder to maintain spacing */}
            <div style={{ width: '16%' }}></div>{/* placeholder to maintain spacing */}
        </Row>
        <Break />
        <Break air v={20} />
        <b>PROGRESS BAR</b>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Background Color </span>
                <HoverQuestion style={hoverStyle} title="The background color of the discount progress bar" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('progress/background', '#f4f4f4', state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Partial Fill Color </span>
                <HoverQuestion style={hoverStyle} title="The interior color of the discount progress bar" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('progress/color', '#ff4d7e', state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Completed Fill Color </span>
                <HoverQuestion style={hoverStyle} title="The 100% complete progress color of the discount progress bar" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('progress/fullColor', '#34b87d', state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}></div>{/* placeholder to maintain spacing */}
            <div style={{ width: '16%' }}></div>{/* placeholder to maintain spacing */}
            <div style={{ width: '16%' }}></div>{/* placeholder to maintain spacing */}
        </Row>
        <Break />
        <Break air v={20} />
        <b>INPUT FIELDS</b>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Text Color </span>
                <HoverQuestion style={hoverStyle} title="The main text color for input fields in the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('input/color')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Background Color </span>
                <HoverQuestion style={hoverStyle} title="The main text color for input fields in the app" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('input/backgroundColor')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Border Color </span>
                <HoverQuestion style={hoverStyle} title="The border color of the input fields" />

                <Break air />
                <HexPicker style={{ width: '54%' }} {...cbnd('input/borderColor')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Border Width </span>
                <HoverQuestion style={hoverStyle} title="The border width of the input fields" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={1} max={5} {...nbnd('input/borderWidth', 1, state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Vertical Padding </span>
                <HoverQuestion style={hoverStyle} title="The amount of vertical space added to the interior of the input fields" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={1} max={10} {...nbnd('input/paddingVertical', 2, state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}>
                <span style={{ color: '#8c8c8c' }}>Horizontal Padding </span>
                <HoverQuestion style={hoverStyle} title="The amount of horizontal padding added to the interior of the input fields" />

                <Break air />
                <InputNumber style={{ padding: 5, borderRadius: 6, height: '38px' }} size="small" min={1} max={10} {...nbnd('input/paddingHorizontal', 5, state.storeID != '-1')} />
            </div>
            <div style={{ width: '16%' }}></div>{/* placeholder to maintain spacing */}
        </Row>
    </>)
}
